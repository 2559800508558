













import { Vue, Component, Emit, Prop, Watch, PropSync } from 'vue-property-decorator';
import { getCitySelect, getCountrySelect, getStateSelect } from '@/api/school_api';
interface AddressList {countryId: number| string, stateId: number | string, cityId: number | string}
@Component
export default class AddressBox extends Vue {
  @PropSync('initial', {default: {countryId: '',stateId: '', cityId: ''}}) address!: AddressList;
  countryList: {id:number, fullName: string}[] = [];
  stateList: {id:number, fullName: string}[] = [];
  cityList: {id:number, fullName: string}[] = [];

  @Watch('initial',{deep: true})
  async initialSelect(newVal: AddressList, oldVal: AddressList){
    // 回显
    if(newVal.countryId){
      try {
        if (newVal.countryId){
          if (oldVal.countryId != newVal.countryId) {
            const stateRet = await getStateSelect(newVal.countryId);
            this.stateList = stateRet.data.data || [{id:0, fullName: 'empty'}];
          }
        } else {
          this.stateList = [];
          this.cityList = [];
          return;
        }
        if (newVal.stateId){
          if (oldVal.stateId != newVal.stateId) {
            const cityRet = await getCitySelect(newVal.stateId)
            this.cityList = cityRet.data.data || [{id:0, fullName: 'empty'}];
          }
        } else {
          this.cityList = [];
        }
      } catch (e) {
      }
    }
  }
  cascaderProp = {
    value: 'id',
    label: 'fullName',
  }
  async mounted(){
    const countryRet = await getCountrySelect();
    if(countryRet.data.code == 1003) {
      this.countryList = countryRet.data.data || [{id:0, fullName: 'empty'}];
    }
    this.change()
  }
  async changeCountry(newVal: number | string){
    const stateRet = await getStateSelect(newVal);
    this.stateList = stateRet.data.data || [{id:0, fullName: 'empty'}];
    this.address.stateId = '';
    this.address.cityId = '';
    this.change()
  }
  async changeState(newVal: number | string){
    this.address.cityId = '';
    const cityRet = await getCitySelect(newVal)
    this.cityList = cityRet.data.data || [{id:0, fullName: 'empty'}];
    this.change()
  }
  @Emit()
  change(){
    return this.address
  }
}
