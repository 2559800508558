



















































































import { Vue, Component, Ref } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { checkAreaCode, regionPhoneList } from '@/utils'
import { UserInfo } from '@/interface';
import { updateUser } from '@/api/user_api';
import { UserStoreModule } from '@/store/UserModule';
import { LayoutStoreModule } from '@/store/LayoutModule';
import AddressBox from '@/view/admin/components/address-box.vue'
@Component({
  name:'adminMyInfo',
  components: {
    AddressBox
  }
})
export default class UserResetPassword extends Vue {
  @Ref('myForm') myForm!: Form;
  regionPhoneList = regionPhoneList;
  form: UserInfo = {
    id: 0,
    name: '',
    nameEn: '',
    gender: '',
    birthday: '',
    company: '',
    position: '',
    mobile: '',
    wechat: '',
    qq: '',
    email: '',
    whatsapp: '',
    zipCode: '',
    fkAreaCountryId: 0,
    fkAreaStateId: 0,
    fkAreaCityId: 0,
    address: '',
    mobileAreaCode: '86',
  }
  rules = {
    name: [
      {required: true, trigger: 'blur', message: this.$t('pleaseInput')}
    ],
    nameEn: [
      {required: true, trigger: 'blur', message: this.$t('pleaseInput')}
    ],
    mobile: [
      {required: true, trigger: 'blur', message: this.$t('pleaseInput')},
      {validator: this.phoneValidate, trigger: 'blur'}
    ],
    mobileAreaCode: [
      {required: true, trigger: 'change', message: this.$t('pleaseSelectPhoneRegion')}
    ]
  }
  initialAddress = {
    countryId: '',
    stateId: '',
    cityId: '',
  }
  mounted(){
    let time = setInterval(()=>{
      if (UserStoreModule.userInfo.id){
        clearInterval(time);
        const userInfo = JSON.parse(JSON.stringify(UserStoreModule.userInfo))
        this.form = userInfo;
        if (userInfo.fkAreaCountryId) {
          const initialAddress = {
            countryId: userInfo.fkAreaCountryId,
            stateId: '',
            cityId: '',
          }
          if(userInfo.fkAreaStateId){
            initialAddress.stateId = userInfo.fkAreaStateId
            if(userInfo.fkAreaCityId){
              initialAddress.cityId = userInfo.fkAreaCityId
            }
          }
          this.initialAddress = initialAddress
        }
        return;
      }
    },300)
  }
  resetValidate(str: string){
    this.myForm.clearValidate(str);
  }
  phoneValidate(rule: any, value: string, callback: Function){
    if (!checkAreaCode(value, this.form.mobileAreaCode as string)){
      callback(new Error(this.$t('errorPhoneNumberInRegion')+''));
      return;
    }
    callback()
  }
  async updateMyInfo(){
    this.form.fkAreaCountryId = parseInt(this.initialAddress.countryId);
    this.form.fkAreaStateId = parseInt(this.initialAddress.stateId);
    this.form.fkAreaCityId = parseInt(this.initialAddress.cityId);

    try {
      await this.myForm.validate()
      const ret = await updateUser(this.form);
      if (ret.data.code == 1003) {
        UserStoreModule.setUserInfo(JSON.parse(JSON.stringify(this.form)));
        this.$message.success(this.$t('sthSuccess', {sth: this.$t('privatePersonalInfoUpdate')})+'');
        LayoutStoreModule.removeNavItem(LayoutStoreModule.navItemActive);
      }
    } catch(e) {
    }
  }
  close(){
    LayoutStoreModule.removeNavItem(LayoutStoreModule.navItemActive);
  }
}
