import { ReqFilterTable } from '@/interface';
import request from '@/utils/request';
// 学校
// 学校列表
export function getSchoolListApi(data: any) {
  return request({
    url: '/appIb/institution/datas',
    data,
    method: 'post',
  })
}
// 获取学校详情
export function getSchoolDetailApi(institutionGuid: string) {
  return request({
    url: '/appIb/institution/detail',
    method: 'get',
    params: {institutionGuid}
  })
}
// 新增
export function addSchoolListApi(data: any) {
  return request({
    url: '/appIb/institution/add',
    method: 'post',
    data,
  })
}
// 编辑
export function editSchoolApi(data: any) {
  return request({
    url: '/appIb/institution/update',
    method: 'post',
    data,
  })
}
// 删除
export function deleteSchoolApi(id: string) {
  return request({
    url: '/appIb/institution/delete',
    method: 'get',
    params:{id}
  })
}
// 保存上传文件
export function saveMediaApi(data: any) {
  return request({
    url: '/appIb/institution/upload',
    method: 'post',
    data,
  })
}
// 学校文件
// 删除文件
export function deleteSchoolMediaApi(id: number) {
  return request({
    url: '/appIb/media/delete',
    method: 'get',
    params:{id}
  })
}
// 上传学校封面
export function uploadMediaPiaApi(data: any) {
  return request({
    url: '/appIb/media/upload',
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data'
    },
    data
  })
}
// 上传附件
export function uploadAttachApi(data: any) {
  return request({
    url: '/appIb/media/uploadAttached',
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data'
    },
    data,
  })
}
// 学校资讯
// 删除
export function deleteSchoolInfoApi(institutionInfoId: number) {
  return request({
    url: '/appIb/institutionInfo/delete',
    method: 'get',
    params:{institutionInfoId}
  })
}
// 新增
export function addSchoolInfoApi(data: any) {
  return request({
    url: '/appIb/institutionInfo/insert',
    method: 'post',
    data,
  })
}
// 编辑
export function editSchoolInfoApi(data: any) {
  return request({
    url: '/appIb/institutionInfo/update',
    method: 'post',
    data,
  })
}
// 资讯类型
// 资讯类型下拉框
export function getSchoolInfoSelectApi() {
  return request({
    url: '/appIb/InfoType/datas',
    method: 'get'
  })
}
// 所接端口号不同于学校的
// 区域管理-国家配置
// 获取所有国家
export function getCountrySelect(){
  return request({
    url: '/appInstitution/areaCountry/getCountryForIb',
    method: 'get',
  })
}
// 州省下拉
export function getStateSelect(country_id: number | string){
  return request({
    url: '/appInstitution/areaState/getStateForIb?id='+country_id,
    method: 'get'
  })
}
// 城市下拉
export function getCitySelect(state_id: number | string){
  return request({
    url: '/appInstitution/areaCity/getCityForIb?id='+state_id,
    method: 'get'
  })
}
export function getUserSchoolList(data:ReqFilterTable<any>){
  return request({
    url: '/appIb/institution/dataForIndex',
    method: 'post',
    data
  })
}
// 所有学校类型
export function getInstitutionAllTypeApi(){
  return request({
    url: '/appInstitution/institution/institutionType',
    method: 'get'
  })
}

export function getInstitutionTypeApi(countryId: number,businessAreaKey:any){
  return request('/appIb/institution/institutionType?countryId=' + countryId+'&businessAreaKey='+businessAreaKey);
}

export function getStateByTypeAndCounty(fkInstitutionTypeId: number, countryId: string,businessAreaKey?:string){
  return request(`/appIb/institution/stateDataByCountryId?fkInstitutionTypeId=${fkInstitutionTypeId}&countryId=${countryId}&businessAreaKey=${businessAreaKey}`);
}

export function getCountryByType(fkInstitutionTypeId: string,businessAreaKey?:string){
  return request(`/appIb/institution/countryDataByInstitutionType?fkInstitutionTypeId=0&businessAreaKey=${businessAreaKey}`);
}
// 获取区域枚举
export function getAreaEnumApi(businessAreaKey:string){
  return request({
    url:'/appIb/institution/businessAreaNameByBusinessAreaKey',
    method:'get',
    params:{businessAreaKey},
  })
}

// 年会学校列表
export function getInstitutionAnnualApi(data:ReqFilterTable<any>){
  return request({
    url: '/appIb/institution/getInstitutionAnnualMeeting',
    method: 'post',
    data,
  })
}
// 年会的国家下拉列表
export function getAnnualMeetingCtyApi(userId: any) {
  return request({
    url: '/appIb/institution/getAnnualMeetingCty',
    method: 'get',
    data: {
      userId,
    }
  })
}
// 新增年会学校
export function addAnnualMeetingApi(data: any){
  return request({
    url: '/appIb/institution/addAnnualMeetingInstitution',
    method: 'post',
    data
  })
}
// 删除年会学校
export function delAnnualMeetingApi(id: any){
  return request({
    url: '/appIb/institution/deleteAnnualMeetingInstitution',
    method: 'get',
    params: { id }
  })
}
// 更新年会学校
export function editAnnualMeetingApi(data: any){
  return request({
    url: '/appIb/institution/updateAnnualMeetingInstitution',
    method: 'post',
    data
  })
}